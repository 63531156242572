import React, { Component } from 'react';
import Room from './Room';

import { Text } from 'office-ui-fabric-react/lib/Text';

import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';

import './styles/LightList.css';
import Hue from './Hue';

interface IRoomListState {
  rooms: any[];
  isLoading: boolean;
}

export default class RoomList extends Component<{}, IRoomListState> {
  constructor(props: Readonly<{}>) {
    super(props);

    this.state = {
      rooms: [],
      isLoading: false
    };
  }

  refreshData() {
    this.setState({ isLoading: true });

    Hue.GetAllRooms()
      .then(rooms => {
        console.log('rooms: ', rooms);
        this.setState({ rooms: rooms, isLoading: false });
      });
  }

  componentDidMount() {
    this.refreshData();
  }

  render() {
    const { rooms, isLoading } = this.state;
    if (isLoading) {
      return (
        <Stack className='details-container'>
          <Text className='container-title' variant='large'>Räume</Text>
          <div className='loading-container'>
            <Spinner size={SpinnerSize.large} label='Loading' />
          </div>
        </Stack>
      );
    }

    return (
      <Stack className='details-container'>
        <Text className='container-title' variant='large'>Räume</Text>
        <div className='flex-container'>
          {rooms.sort((a, b) => (a.name > b.name) ? 1 : -1)
            .map((room, key) => (
              <Room key={key} {...room} />
            ))}
        </div>
      </Stack>
    );
  }
}
