import React from 'react';
import { Nav, INavLink } from 'office-ui-fabric-react/lib/Nav';

const menu = [
    {
        key: 'lights',
        name: 'Lampen',
        icon: 'Lightbulb'
    },
    {
        key: 'rooms',
        name: 'Räume',
        icon: 'Home'
    },
    {
        key: 'scenes',
        name: 'Szenen',
        icon: 'Light'
    },
    {
        key: 'rules',
        name: 'Routinen',
        icon: 'Processing'
    },
    {
        key: 'settings',
        name: 'Einstellungen',
        icon: 'Settings'
    }
];

export function Navigation({ setDetail = (value: string) => { console.log(value); } }) {
    const m = menu.map(item => { return { ...item, onClick: () => setDetail(item.key) } as INavLink; });

    return <Nav
        groups={[
            {
                name: '',
                links: m
            }
        ]} />;

}
