import React, { Component } from 'react';

import ConnectBridge from './components/ConnectBridge';

import Configuration from './components/Configuration';
import { Navigation } from './components/Navigation';
import AppLayout from './components/AppLayout';
import { DetailView } from './components/DetailView';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
initializeIcons(/* optional base url */);

interface IAppState {
  detailType: string;
}

export default class App extends Component<{}, IAppState> {
  constructor(props: Readonly<{}>) {
    super(props);

    let startPosition = Configuration.StartPosition;

    if (startPosition === 'last') {
      startPosition = Configuration.LastPosition;
    }

    this.state = {
      detailType: startPosition
    };
  }

  setDetail(detailType: string) {
    Configuration.setLastPosition(detailType);
    this.setState({ detailType: detailType });
  }

  render() {
    const { detailType } = this.state;

    if (Configuration.RegistredHues.length === 0) {
        return (<ConnectBridge/>);
    }

    return (
      <AppLayout
        renderNavigation={() => <Navigation setDetail={(detailType) => this.setDetail(detailType)} />}
        renderDetail={() => <DetailView detailType={detailType} />}
      />
    );
  }
}
