import React, { Component } from 'react';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

import Hue, { IHueConfig } from './Hue';
import Configuration from './Configuration';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';

interface IConnectBridgeState {
  bridges: any[];
  isLoading: boolean;
  pressLinkButton: boolean;
  tryToConnect: boolean;
  currentIp: string;
}

export default class ConnectBridge extends Component<{}, IConnectBridgeState> {
  hue: any;
  constructor(props: Readonly<{}>) {
    super(props);

    this.state = {
      bridges: [],
      isLoading: false,
      pressLinkButton: false,
      tryToConnect: false,
      currentIp: ''
    };
  }

  private _intervalHandle?: NodeJS.Timeout = undefined;
  private _timeoutHandle?: NodeJS.Timeout = undefined;

  endTryConnectBridge() {
    console.log('endTryConnectBridge');
    if (this._intervalHandle !== undefined) {
      console.log('endTryConnectBridge.clearInterval');
      clearInterval(this._intervalHandle);
      this._intervalHandle = undefined;
    }

    if (this._timeoutHandle !== undefined) {
      clearTimeout(this._timeoutHandle);
    }

    this.setState({ tryToConnect: false, pressLinkButton: false });
  }

  tryToConnectBridge(ip: string): void {
    this.setState({ tryToConnect: true });

    this._intervalHandle = setInterval(() => {
      Hue.tryConnect(ip)
        .then(result => {
          console.log('tryConnect', result);
          if (result[0].error != null) {
            if (result[0].error.type === 101) {
              console.log('Press Link-Button');
              this.setState({ pressLinkButton: true });
            }
          } else if (result[0].success != null) {
            console.log('success');
            const newHueConnection: IHueConfig = { ip: ip, userKey: result[0].success.username };
            Configuration.registerHue(newHueConnection);
            Hue.Select(newHueConnection);
            this.endTryConnectBridge();
          } else {
            console.log('unbekannte Antwort: ', result);
          }
        });
    }, 500);

    this._timeoutHandle = setTimeout(() => {
      this.endTryConnectBridge();
    }, 10200);
  }

  render() {
    const { currentIp } = this.state;

    return (
      <div>
        <h1>Hue-Bridges</h1>
        <Label>IP eingeben</Label>
        <TextField value={currentIp} onChange={(ev, newValue: string | undefined) => { this.setState({ currentIp: newValue as string }); }}></TextField>
        <button onClick={() => this.tryToConnectBridge(currentIp)}>refresh</button>
        {this.state.tryToConnect &&
          <Spinner label='Verbindungsversuch ...' ariaLive='assertive' labelPosition='right' size={SpinnerSize.large} />
        }
        {this.state.pressLinkButton &&
          <Label>Den Verbindungsknopf auf der Hue Bridge drücken.</Label>
        }
      </div>
    );
  }
}
