import React from "react";
import RulesList from "./RulesList";
import LightList from "./LightList";
import RoomList from "./RoomList";
import Settings from "./Settings";

export function DetailView({ detailType = "lights" }) {
  switch (detailType) {
    case "rules":
      return <RulesList />;
    case "lights":
      return <LightList />;
    case "rooms":
      return <RoomList />;
      case "settings":
        return <Settings />;
      default:
      return <div>Keine Details für {detailType}</div>;
  }
}
