interface ISettings {
	shadows: boolean;
	refresh: boolean;
	startPosition: string;
}

class Configuration {
	private static _instance: Configuration = new Configuration();

	private _settings: ISettings = { shadows: true, refresh: true, startPosition: 'lights' };
	private _lastPosition: string = 'lights';
	private _registredHues: any[] = [];

	public get Shadows(): boolean {
		return this._settings.shadows;
	}

	public get Refresh(): boolean {
		return this._settings.refresh;
	}

	public get StartPosition(): string {
		return this._settings.startPosition;
	}

	public get LastPosition(): string {
		return this._lastPosition;
	}

	public get RegistredHues(): any[] {
		return this._registredHues;
	}

	constructor() {
		if (Configuration._instance) {
			return Configuration._instance;
		}

		let settingsString = localStorage.getItem('settings');
		if (settingsString != null) {
			this._settings = JSON.parse(settingsString);
		}

		settingsString = localStorage.getItem('registredHues');
		if (settingsString != null) {
			this._registredHues = JSON.parse(settingsString);
		}

		this._lastPosition = localStorage.getItem('lastPosition') as string;

		Configuration._instance = this;
	}

	writeCurrentSettings() {
		localStorage.setItem('settings', JSON.stringify(this._settings));
	}

	setShadows(value: boolean): void {
		this._settings.shadows = value;
		this.writeCurrentSettings();
	}

	setRefresh(value: boolean): void {
		this._settings.refresh = value;
		this.writeCurrentSettings();
	}

	setStartPosition(value: string): void {
		this._settings.startPosition = value;
		this.writeCurrentSettings();
	}

	setLastPosition(value: string): void {
		this._lastPosition = value;
		localStorage.setItem('lastPosition', value);
	}

	registerHue(hue: any) {
		this._registredHues.push(hue);
		localStorage.setItem('registredHues', JSON.stringify(this._registredHues));
	}
}

export default new Configuration();
