import React, { Component } from 'react';
import Light from './Light';
import { Text } from 'office-ui-fabric-react/lib/Text';

import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';

import './styles/LightList.css';

import Hue from './Hue';

interface ILightListState {
  lights: any[];
  isLoading: boolean;
}

export default class LightList extends Component<{}, ILightListState> {
  constructor(props: Readonly<{}>) {
    super(props);

    this.state = {
      lights: [],
      isLoading: false
    };
  }

  refreshData() {
    this.setState({ isLoading: true });

    Hue.GetAllLights()
      .then((lights) => this.setState({ lights: lights, isLoading: false }))
      .catch((err) => console.log(err));
  }

  componentDidMount() {
    this.refreshData();
  }

  render() {
    const { lights, isLoading } = this.state;
    if (isLoading) {
      return (
        <Stack className='details-container'>
          <Text className='container-title' variant='large'>Lampen</Text>
          <div className='loading-container'>
            <Spinner size={SpinnerSize.large} label='Loading' />
          </div>
        </Stack>
      );
    }

    return (
      <Stack className='details-container'>
        <Text className='container-title' variant='large'>Lampen</Text>
        <div className='flex-container'>
          {lights.sort((a, b) => (a.name > b.name) ? 1 : -1)
            .map((light, key) => (
              <Light key={key} {...light} />
            ))}
        </div>
      </Stack>
    );
  }
}
