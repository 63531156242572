import React, { Component } from 'react';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { ComboBox, IComboBoxOption } from 'office-ui-fabric-react/lib/ComboBox';
import Configuration from './Configuration';

const INITIAL_OPTIONS: IComboBoxOption[] = [
    { key: 'last', text: 'letzte Position' },
    { key: 'lights', text: 'Lampen' },
    { key: 'rooms', text: 'Zimmer' },
    { key: 'scenes', text: 'Szenen' },
    { key: 'rules', text: 'Routinen' }
];

interface ISettings {
    shadows?: boolean;
    refresh?: boolean;
    startPosition?: string;
}

export default class Settings extends Component<{}, ISettings> {
    constructor(props: Readonly<{}>) {
        super(props);

        this.state = { shadows: true, refresh: true, startPosition: 'lights' };
    }

    setShadow(value?: boolean): void {
        const v = value ? true : false;
        this.setState({ shadows: v });

        Configuration.setShadows(v);
    }

    setStartPosition(value: string): void {
        this.setState({ startPosition: value });

        Configuration.setStartPosition(value);
    }

    setRefresh(value?: boolean): void {
        const v = value ? true : false;
        this.setState({ refresh: v });

        Configuration.setRefresh(v);
    }

    componentDidMount() {
        this.setState({ shadows: Configuration.Shadows, refresh: Configuration.Refresh, startPosition: Configuration.StartPosition });
    }

    render() {
        return (
            <Stack className='details-container'>
                <Text className='container-title' variant='large'>Einstellungen</Text>
                <div className='flex-container'>
                    <Stack>
                        <Toggle label='Schatten'
                            onText='mit'
                            offText='ohne'
                            onChange={(ev, checked?: boolean) => { this.setShadow(checked); }}
                            checked={this.state.shadows} />
                        <Toggle label='Automatische Aktualisierung'
                            onText='mit'
                            offText='ohne'
                            onChange={(ev, checked?: boolean) => { this.setRefresh(checked); }}
                            checked={this.state.refresh} />
                        <ComboBox label='Startposition'
                            options={INITIAL_OPTIONS}
                            selectedKey={this.state.startPosition}
                            onChange={(ev, option, index, value) => { this.setStartPosition(option ? option.key as string : 'lights'); }} />
                    </Stack>
                </div>
            </Stack>
        );
    }
}
