import React, { Component } from 'react';

import { Rule } from './Rule';
import Hue from './Hue';

interface IRuleListState {
  rules: any[];
  isLoading: boolean;
}

export default class RulesList extends Component<{}, IRuleListState> {
  constructor(props: Readonly<{}>) {
    super(props);

    this.state = {
      rules: [],
      isLoading: false
    };
  }

  refreshData() {
    this.setState({ isLoading: true });

    Hue.getAllRules()
      .then(rules => {
        this.setState({ rules: rules, isLoading: false });
      });
  }

  componentDidMount() {
    this.refreshData();
  }

  render() {
    const { rules, isLoading } = this.state;
    if (isLoading) {
      return <div>
        <div>Loading</div></div>;
    }

    console.log(rules);

    return <div>
      <div className='detail-header'>Routinen</div>
      <ul>
        {rules.map((rule, key) => <Rule key={key} {...rule} />)}
      </ul>
    </div>;
  }
}
