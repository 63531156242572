import React, { Component } from 'react';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';

import { Toggle } from 'office-ui-fabric-react/lib/Toggle';

import Light from './Light';
import Configuration from './Configuration';

import Hue from './Hue';

import './styles/Room.css';

interface IRoomState {
    showDialog: boolean;
    room: any;
    lights: any[];
    autoUpdate: boolean;
    showShadow: boolean;
}

export default class Room extends Component<{}, IRoomState> {
    intervalId: any;

    constructor(props: Readonly<{}>) {
        super(props);

        this.state = {
            room: props,
            showDialog: false,
            lights: [],
            showShadow: Configuration.Shadows,
            autoUpdate: Configuration.Refresh
        };
    }

    switchLight(on: boolean) {
        let id = this.state.room.id;

        if (on) {
            this.setState({ ...this.state.room, state: { ...this.state.room.state, all_on: true } });
            Hue.SwitchGroupOn(id);
        } else {
            this.setState({ ...this.state.room, state: { ...this.state.room.state, all_on: false } });
            Hue.SwitchGroupOff(id);
        }

        setTimeout(() => {
            this.getStatus(id);
        }, 300);
    }

    getLights(ids: string[]): any[] {
        return ids.map(id => Hue.GetLight(id).then(light => {
            this.state.lights.push(light);
            return this.setState({ lights: this.state.lights }) ;
        }));
    }

    showDialog() {
        this.setState({ showDialog: true });
    }

    closeDialog() {
        this.setState({ showDialog: false });
    }

    getStatus(id: string) {
        Hue.GetRoom(id)
            .then(room => {
                this.setState({ room: room });
            });
    }

    componentDidMount() {
        if (this.state.autoUpdate) {
            this.intervalId = setInterval(() => this.getStatus(this.state.room.id), 2000);
        }
    }

    componentWillUnmount() {
        if (this.intervalId != null) {
            clearInterval(this.intervalId);
        }
    }

    render() {
        const { room, showDialog, showShadow } = this.state;

        let style: any = {
            display: 'block',
            backgroundColor: '#f0f0f0',
            width: '200px',
            margin: '10px',
            padding: '10px',
            cursor: 'default'
        };

        if (showShadow) {
            style = {
                ...style, boxShadow: '7px 7px 5px grey'
            };
        }

        return (<div style={style}>
            <Stack>
                <Text variant='mediumPlus'>{room.name}</Text>
                <FontIcon iconName='Settings'
                    onClick={() => {
                        this.setState({ lights: [] });
                        this.getLights(room.lights);
                        this.showDialog();
                    }}
                    style={{ cursor: 'pointer' }} />
                <Toggle
                    checked={room.state.all_on || room.state.any_on}
                    onText='An'
                    offText='Aus'
                    onChange={(ev, checked?: boolean) => {
                        this.switchLight(checked as boolean);
                    }} />
                <Dialog hidden={!showDialog}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: 'Eigenschaften'
                    }}
                    modalProps={{
                        isBlocking: true,
                        styles: { main: { minWidth: 450 } }
                    }}
                >
                    <TextField label='Name' placeholder='Name eingeben' value={room.name} />
                    <Label>Lampen</Label>
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        overflow: 'auto',
                        height: '60%',
                        width: '100%',
                        alignContent: 'flex-start'
                    }}>
                        {this.state.lights
                            .sort((a, b) => a.name > b.name ? 1 : -1)
                            .map((light, key) => (
                                <Light key={key} {...light} />
                            ))}
                    </div>
                    <DialogFooter>
                        <PrimaryButton onClick={() => {
                            this.closeDialog();
                        }} text='Setzen' />
                        <DefaultButton onClick={() => {
                            this.closeDialog();
                        }} text='Abbrechen' />
                    </DialogFooter>
                </Dialog>
            </Stack>
        </div>);
    }
}
