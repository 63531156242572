import React, { Component } from 'react';
import './styles/AppLayout.css';

interface AppLayoutProps {
  renderNavigation: () => any;
  renderDetail: () => any;
}

export default class AppLayout extends Component<AppLayoutProps, {}> {
  render() {
    let { renderNavigation, renderDetail } = this.props;

    if (renderDetail == null) {
      renderDetail = () => <div></div>;
    }

    return (
      <div className='App'>
        <div className='body'>
          <div className='sidebar'>{renderNavigation()}</div>
          <div className='content'>{renderDetail()}</div>
          </div>
      </div>
    );
  }
}
