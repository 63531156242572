import React, { Component } from 'react';

import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { Slider } from 'office-ui-fabric-react/lib/Slider';
import { setTimeout } from 'timers';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { Color } from 'csstype';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { ColorPicker } from 'office-ui-fabric-react/lib/ColorPicker';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';

import Configuration from './Configuration';
import Hue from './Hue';

interface ILightState {
  light: any;
  brightness: number;
  colorTemperature: number;
  on?: boolean;
  color?: Color;
  tempColor?: Color;
  showDialog: boolean;
  showShadow: boolean;
  autoUpdate: boolean;
}

export default class Light extends Component<{}, ILightState> {
  lightId: number = 0;
  intervalId: any;

  constructor(props: Readonly<{}>) {
    super(props);

    this.state = this.getStateValues(props);
  }

  switchLight(checked?: boolean) {
    const id = this.state.light.id;

    if (checked) {
      Hue.SwitchLightOn(id)
        .then(() => {
          this.setState({ on: checked });
          this.getStatus(id);
        });
    } else {
      Hue.SwitchLightOff(id)
        .then(() => {
          this.setState({ on: checked });
          this.getStatus(id);
        });
    }

    // this.setState({ on: checked });

    // setTimeout(() => {
    //   this.getStatus(id);
    // }, 300);
  }

  getStatus(id: string) {
    Hue.GetLight(id)
      .then(data => {
        if (data != null) {
          this.setState(this.getStateValues({ ...data, color: this.hasColor(data) ? this.hexColor(data) : null }));
        }
      })
      .catch((err) => console.log(err));
  }

  getColor(light: any): string {
    if (!this.hasColor(light)) return 'grey';

    return this.hexColor(light);
  }

  hasColor(light: any): boolean {
    return light != null && light.state.xy != null;
  }

  hasColorTemperature(light: any): boolean {
    return light != null && light.state.ct != null;
  }

  getStateValues(light: any) {
    return {
      light: light,
      brightness: light.state.bri,
      colorTemperature: light.state.ct,
      on: light.state.on,
      color: this.getColor(light),
      tempColor: this.state == null ? 'lightGray' : this.state.tempColor,
      showDialog: this.state == null ? false : this.state.showDialog,
      showShadow: Configuration.Shadows,
      autoUpdate: Configuration.Refresh
    };
  }

  changeBrightness(value: number) {
    const id = this.state.light.id;
    this.setState({ brightness: value });

    Hue.SetBrightness(id, value);

    setTimeout(() => {
      this.getStatus(id);
    }, 300);
  }

  changeColorTemperature(value: number) {
    const id = this.state.light.id;
    this.setState({ colorTemperature: value });

    Hue.SetColorTemperature(id, value);

    setTimeout(() => {
      this.getStatus(id);
    }, 300);
  }

  changeColor(value: number[]) {
    const id = this.state.light.id;

    Hue.SetColor(id, value);

    setTimeout(() => {
      this.getStatus(id);
    }, 300);
  }

  getColorStyle(light: any): any {
    if (this.hasColor(light) && light.state.on) {
      return { width: '20px', height: '20px', borderRadius: '50%', backgroundColor: this.state.color, cursor: 'pointer' };
    } else {
      return { width: '20px', height: '20px', borderRadius: '50%', backgroundColor: 'lightgray', pointerEvents: 'none' };
    }
  }

  componentDidMount() {
    if (this.state.autoUpdate) {
      this.intervalId = setInterval(() => this.getStatus(this.state.light.id), 2000);
    }
  }

  componentWillUnmount() {
    if (this.intervalId != null) {
      clearInterval(this.intervalId);
    }
  }

  shouldComponentUpdate(props: Readonly<{}>, state: ILightState) {
    if (props !== this.state.light) {
      this.setState({ light: props });
    }

    return true;
  }

  getRgbFromName(color: string): any {
    const ctx = document.createElement('canvas').getContext('2d');
    if (ctx == null) {
      return {
        r: 255,
        g: 255,
        b: 255
      };
    }

    ctx.fillStyle = color;

    const hex = ctx.fillStyle;
    return {
      r: parseInt(hex.substr(1, 2), 16),
      g: parseInt(hex.substr(3, 2), 16),
      b: parseInt(hex.substr(5, 2), 16)
    };
  }

  hexColor(light: any): string {
    let state = light.state;
    const x = state.xy[0]; // the given x value
    const y = state.xy[1]; // the given y value
    const z = 1.0 - x - y;
    const Y = state.bri; // The given brightness value
    const X = (Y / y) * x;
    const Z = (Y / y) * z;
    const red1 = X * 1.656492 - Y * 0.354851 - Z * 0.255038;
    const green1 = -X * 0.707196 + Y * 1.655397 + Z * 0.036152;
    const blue1 = X * 0.051713 - Y * 0.121364 + Z * 1.011530;
    const red2 = (red1 <= 0.0031308 ? 12.92 * red1 : (1.0 + 0.055) * Math.pow(red1, (1.0 / 2.4)) - 0.055);
    const green2 = (green1 <= 0.0031308 ? 12.92 * green1 : (1.0 + 0.055) * Math.pow(green1, (1.0 / 2.4)) - 0.055);
    const blue2 = (blue1 <= 0.0031308 ? 12.92 * blue1 : (1.0 + 0.055) * Math.pow(blue1, (1.0 / 2.4)) - 0.055);
    const correction = Math.max(red2, green2, blue2);
    const red = Math.floor(Math.max(red2 / correction, 0) * 255);
    const green = Math.floor(Math.max(green2 / correction, 0) * 255);
    const blue = Math.floor(Math.max(blue2 / correction, 0) * 255);
    const colourHex = '#' +
      red.toString(16).padStart(2, '0') +
      green.toString(16).padStart(2, '0') +
      blue.toString(16).padStart(2, '0');
    return colourHex;
  }

  getCieFromRgb(color: string): number[] {
    const { r, g, b } = this.getRgbFromName(color);
    const X = 0.4124 * r + 0.3576 * g + 0.1805 * b;
    const Y = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    const Z = 0.0193 * r + 0.1192 * g + 0.9505 * b;

    return [X / (X + Y + Z), (Y / (X + Y + Z))];
  }

  render() {
    const light = this.state.light;
    this.lightId = light.id;

    let style: any = {
      display: 'block',
      backgroundColor: '#f0f0f0',
      width: '200px',
      margin: '10px',
      padding: '10px',
      cursor: 'default'
    };

    if (Configuration.Shadows) {
      style = {
        ...style, boxShadow: '7px 7px 5px grey'
      };
    }

    return (
      <div style={style}>
        <Stack>
          <Stack horizontal={true} tokens={{ childrenGap: 5, padding: '0px 0px 10px 0px' }} verticalAlign='center' >
            <FontIcon iconName='Lightbulb' />
            <Text variant='mediumPlus'>{light.name}</Text>
          </Stack>
          <Toggle
            disabled={!light.state.reachable}
            checked={this.state.on}
            onText='An'
            offText='Aus'
            onChange={(ev, checked?: boolean) => {
              this.switchLight(checked);
            }}
          />
          <Slider label='Helligkeit'
            value={this.state.brightness}
            disabled={!light.state.reachable || !this.state.on}
            max={254}
            min={1}
            valueFormat={(value) => `${value}%`}
            showValue={false}
            onChanged={(ev, value) => this.changeBrightness(value)} />
          <Slider label='Farbtemperatur'
            value={this.state.colorTemperature}
            disabled={!light.state.reachable || !this.state.on || !this.hasColorTemperature(light)}
            max={500}
            min={153}
            valueFormat={(value) => `${value}%`}
            showValue={false}
            onChanged={(ev, value) => this.changeColorTemperature(value)} />
          <div style={this.getColorStyle(this.state.light)} onClick={() => {
            this.setState({ showDialog: true, tempColor: this.state.color ? this.state.color : 'lightgray' });
          }}>
            <Dialog hidden={!this.state.showDialog}
              dialogContentProps={{
                type: DialogType.normal,
                title: 'Farbe einstellen'
              }}
              modalProps={{
                isBlocking: true
              }}
            >
              <ColorPicker color={this.state.tempColor as string}
                onChange={(ev, color) => this.setState({ tempColor: color.str })} />
              <DialogFooter>
                <PrimaryButton onClick={() => {
                  this.changeColor(this.getCieFromRgb(this.state.tempColor as string));
                  this.setState({ showDialog: false });
                }} text='Setzen' />
                <DefaultButton onClick={() => {
                  this.setState({ showDialog: false, tempColor: undefined });
                }} text='Abbrechen' />
              </DialogFooter>
            </Dialog>
          </div>
        </Stack>
      </div>
    );
  }
}
