import Configuration from './Configuration';
import { IPromise } from 'q';

export interface IHueConfig {
	ip: string;
	userKey: string;
}

type RoomClass = 'Living room' | 'Kitchen' | 'Dining' | 'Bedroom'
	| 'Kids bedroom' | 'Bathroom' | 'Nursery' | 'Recreation' | 'Office'
	| 'Gym' | 'Hallway' | 'Toilet' | 'Front door' | 'Garage' | 'Terrace'
	| 'Garden' | 'Driveway' | 'Carport' | 'Other';

type RoomClass130 = 'Home' | 'Downstairs' | 'Upstairs' | 'Top floor'
	| 'Attic' | 'Guest room' | 'Staircase' | 'Lounge' | 'Man cave'
	| 'Computer' | 'Studio' | 'Music' | 'TV' | 'Reading' | 'Closet'
	| 'Storage' | 'Laundry room' | 'Balcony' | 'Porch' | 'Barbecue' | 'Pool';

type GroupType = 'Luminaire' | 'Lightsource' | 'LightGroup' | 'Room' | 'Entertainment' | 'Zone';

interface IGroup {
	name: string;
	type: GroupType;
	lights: string[];
	[propName: string]: any;
}

interface IRoom extends IGroup {
	class: RoomClass | RoomClass130;
}

interface ISetLightStatus {
	on?: boolean;
	bri?: number;
	hue?: number;
	sat?: number;
	xy?: number[];
	ct?: number;
	colormode?: string;
}

class Hue {
	private static _instance: Hue = new Hue();
	private _registredHues: IHueConfig[] = [];
	private _currentConnectedHue?: IHueConfig = undefined;

	constructor() {
		if (Hue._instance) {
			return Hue._instance;
		}

		this._registredHues = Configuration.RegistredHues;
		if (this._registredHues.length > 0) {
			this._currentConnectedHue = this._registredHues[0];
		}

		Hue._instance = this;
	}

	Select(hue: IHueConfig) {
		this._currentConnectedHue = hue;
	}

	private getServerString(ip: string): string {
		return window.location.protocol + '//' + ip;
	}

	private GetConnectionString(): string {
		if (this._currentConnectedHue == null) {
			throw new Error('Keine aktive Hue ausgewählt.');
		}

		return this.getServerString(this._currentConnectedHue.ip) + '/api/' + this._currentConnectedHue.userKey;
	}

	Add(hue: IHueConfig): void {
		this._registredHues.push(hue);
		localStorage.setItem('registredHues', JSON.stringify(this._registredHues));
	}

	private GetAllGroups(): IPromise<IGroup[]> {
		if (this._currentConnectedHue == null) {
			throw new Error('Keine aktive Hue ausgewählt.');
		}

		return fetch(this.GetConnectionString() + '/groups')
			.then(response => {
				return response.json();
			})
			.then(data => {
				const liste = [];

				for (const propertyName in data) {
					liste.push({ ...data[propertyName], id: propertyName });
				}

				return liste;
			});
	}

	GetAllRooms(): IPromise<IRoom[]> {
		return this.GetAllGroups()
			.then((groups) => groups.map(group => { return { ...group, class: group.class }; })
				.filter((group => group.type === 'Room')));
	}

	GetRoom(id: string): IPromise< IRoom> {
		if (this._currentConnectedHue == null) {
			throw new Error('Keine aktive Hue ausgewählt.');
		}

		return fetch(this.GetConnectionString() + '/groups/' + id)
			.then(response => {
				return response.json();
			})
			.then(room => {
				return { ...room, id: id };
			});
	}

	SwitchGroupOn(id: string): IPromise<any> {
		return fetch(this.GetConnectionString() + '/groups/' + id + '/action', {
			method: 'PUT',
			headers: {
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ on: true })
		});
	}

	SwitchGroupOff(id: string): IPromise<any> {
		return fetch(this.GetConnectionString() + '/groups/' + id + '/action', {
			method: 'PUT',
			headers: {
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ on: false })
		});
	}

	GetAllLights() {
		if (this._currentConnectedHue == null) {
			throw new Error('Keine aktive Hue ausgewählt.');
		}

		return fetch(this.GetConnectionString() + '/lights')
			.then(response => {
				return response.json();
			})
			.then(data => {
				const liste = [];

				const lights = data;

				for (const propertyName in lights) {
					liste.push({ ...lights[propertyName], id: propertyName });
				}

				return liste;
			});
	}

	GetLight(id: string) {
		if (this._currentConnectedHue == null) {
			throw new Error('Keine aktive Hue ausgewählt.');
		}

		return fetch(this.GetConnectionString() + '/lights/' + id, {
			method: 'GET'
			// headers: {
			// 	'Access-Control-Allow-Origin': this.getServerString(this._currentConnectedHue.ip),
			// 	'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin'
			// }
		})
			.then(response => {
				return response.json();
			})
			.then(data => {
				return { ...data, id: id };
			});
	}

	SwitchLightOn(id: string): IPromise<any> {
		return this.SetLightStatus(id, { on: true });
	}

	SwitchLightOff(id: string): IPromise<any> {
		return this.SetLightStatus(id, { on: false });
	}

	SetColor(id: string, xy: number[]): IPromise<any> {
		return this.SetLightStatus(id, { xy: xy, colormode: 'xy' });
	}

	SetBrightness(id: string, bri: number): IPromise<any> {
		return this.SetLightStatus(id, { bri: bri });
	}

	SetColorTemperature(id: string, ct: number): IPromise<any> {
		return this.SetLightStatus(id, { ct: ct, colormode: 'ct' });
	}

	private SetLightStatus(id: string, status: ISetLightStatus): IPromise<any> {
		// const xhr = new XMLHttpRequest();

		// xhr.open(
		// 	'PUT',
		// 	this.GetConnectionString() + '/lights/' +
		// 	id +
		// 	'/state'
		// );
		// xhr.send(JSON.stringify(status));

		return fetch(this.GetConnectionString() + '/lights/' + id + '/state', {
			method: 'PUT',
			body: JSON.stringify(status)
		})
			.then(response => {
				return response.json();
			});
	}

	getAllRules() {
		if (this._currentConnectedHue == null) {
			throw new Error('Keine aktive Hue ausgewählt.');
		}

		return fetch(this.GetConnectionString() + '/rules')
			.then(response => {
				return response.json();
			})
			.then(rules => {
				console.log('rules: ', rules);
				const liste = [];

				for (const propertyName in rules) {
					liste.push({ ...rules[propertyName], id: propertyName });
				}

				return liste;
			});
	}

	tryConnect(ip: string): Promise<any> {
		console.log('window.location: ' + window.location.protocol);
		const address = this.getServerString(ip) + '/api';

		const settings = {
			mode: 'no-cors',
			method: 'POST',
			headers: {
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ devicetype: 'hue-commander' })
		};

		return fetch(address, settings)
			.then(response => {
				return response.json();
			});
	}
}

export default new Hue();
